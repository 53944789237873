import { library, dom } from "@fortawesome/fontawesome-svg-core";
import "./light";
import "./solid";
import "./brands";

// You have to import individually because our bundler isn't very smart...
import { faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleRight";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleLeft";
import { faAngleRight} from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAsterisk} from "@fortawesome/pro-regular-svg-icons/faAsterisk";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faBook } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faChevronSquareLeft } from "@fortawesome/pro-regular-svg-icons/faChevronSquareLeft";
import { faChevronSquareRight } from "@fortawesome/pro-regular-svg-icons/faChevronSquareRight";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faHeadphones } from "@fortawesome/pro-regular-svg-icons/faHeadphones";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";

library.add([
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faAsterisk,
    faBars,
    faBook,
    faChevronSquareLeft,
    faChevronSquareRight,
    faImage,
    faFilePdf,
    faSearch,
    faSpinnerThird,
    faTimes,
    faHeadphones,
    faChevronRight
]);

dom.watch();
