import React from "react";
import PropTypes from "prop-types";
import InlineSearch from "../InlineSearch/InlineSearch";
import $ from "jquery";

function stopBodyScrolling(bool) {
  if (bool === true) {
    document.body.addEventListener("touchmove", freezeVp, false);
  } else {
    document.body.removeEventListener("touchmove", freezeVp, false);
  }
}

const freezeVp = function (e) {
  e.preventDefault();
};

class HamburgerMenu extends React.Component {
  static propTypes = {
    menus: PropTypes.array,
    isSignInOn: PropTypes.bool,
    isSearchOn: PropTypes.bool,
    searchInNav: PropTypes.bool,
    showSubscribeBtn: PropTypes.bool,
    includeSubscribe: PropTypes.bool
  };

  static defaultProps = {
    menus: [
      {
        label: "Main Navigation",
        children: [
          {
            label: "APPLICATIONS",
            url: "/category-group/clinical-focus",
            children: [
              { label: "CANCER", url: "/category/cancer" },
              { label: "CLINICAL TRIALS", url: "/category/clinical-trials" },
              { label: "GENETICS", url: "/category/genetics" },
              { label: "GENOMICS & PROTEOMICS", url: "/category/genomics-proteomics" },
              { label: "HEMATOLOGY", url: "/category/hematology" },
              { label: "IMMUNOLOGY", url: "/category/immunology" },
              { label: "INFECTIOUS DISEASE", url: "/category/infectious-disease" },
              { label: "MICROBIOLOGY", url: "/category/microbiology" },
              { label: "MOLECULAR DIAGNOSTICS", url: "/category/molecular-diagnostics" },
              { label: "NEUROSCIENCE", url: "/category/neuroscience" },
              { label: "PATHOLOGY", url: "/category/pathology" },
              { label: "PRECISION MEDICINE ", url: "/category/precision-medicine" },
              { label: "PUBLIC HEALTH", url: "/category/public-health" },
              { label: "THERAPEUTICS", url: "/category/therapeutics" },
              { label: "WOMEN'S HEALTH", url: "/category/womens-health" }
            ]
          },
          {
            label: "MANAGEMENT & CAREERS",
            url: "/category-group/management-careers",
            children: [
              { label: "BUSINESS", url: "/category/business" },
              { label: "CAREER DEVELOPMENT", url: "/category/career-development" },
              { label: "LEADERSHIP", url: "/category/leadership" },
              { label: "REGULATORY AND SAFETY", url: "/category/regulatory-safety" },
              { label: "STAFFING AND TRAINING", url: "/category/staffing-training" }
            ]
          },
          {
            label: "TECHNOLOGY",
            url: "/category-group/tools-techniques",
            children: [
              { label: "AUTOMATION", url: "/category/automation" },
              { label: "CELL & TISSUE CULTURE", url: "/category/cell-tissue-culture" },
              { label: "CLINICAL CHEMISTRY", url: "/category/clinical-chemistry" },
              { label: "CYTOMETRY", url: "/category/cytometry" },
              { label: "INFORMATICS", url: "/category/informatics" },
              { label: "PCR / RTPCR", url: "/category/pcr-rtpcr" },
              { label: "POINT-OF-CARE TESTING", url: "/category/point-of-care-testing" },
              { label: "SAMPLE PREP & STORAGE", url: "/category/sample-prep-storage" }
            ]
          },
          { label: "NEWS", url: "/type/news" },
          { label: "THOUGHT LEADERSHIP", url: "/type/thought-leadership" },
          {
            label: "EVENTS",
            url: "/type/webinars",
            children: [
              { label: "WEBINARS", url: "/type/webinars" },
              { label: "DIGITAL FORUMS", url: "/type/digital-forums" }
            ]
          },
          { label: "TRENDS", url: "/series/trends" },
          {
            label: "RESOURCES",
            url: "",
            children: [
              { label: "EBOOKS", url: "/type/ebooks" },
              { label: "INFOGRAPHICS", url: "/type/infographics" },
              { label: "MAGAZINE", url: "/magazine" },
              { label: "WHITE PAPERS", url: "/type/white-papers" }
            ]
          }
        ]
      }
    ],
    isSignInOn: false,
    isSearchOn: false,
    searchInNav: false,
    showSubscribeBtn: true,
    includeSubscribe: false
  };

  state = {
    open: false,
    menu: {}
  };

  createMarkup = (html) => {
    return {
      __html: html
    };
  };

  _toggleMenu = () => {
    if (!this.state.open) {
      $("html").addClass("fixed");
      $("#TopAd").addClass("closed");
      $("#MainHeader").addClass("mobile-open");
      stopBodyScrolling(true);
    } else {
      $("html").removeClass("fixed");
      $("#TopAd").removeClass("closed");
      $("#MainHeader").removeClass("mobile-open");
      stopBodyScrolling(false);
    }
    this.setState({ open: !this.state.open });
  };

  _toggleMenuItem = (idx) => {
    this.setState((prevState) => {
      const updatedMenu = {
        ...prevState.menu,
        children: prevState.menu.children.map((child, childIdx) => ({
          ...child,
          open: childIdx === idx ? !child.open : false
        }))
      };
      return { menu: updatedMenu };
    });
  };

  _displayMenuItems = (child, idx) => {
    const hasChildren = child.children && child.children.length > 0;

    return (
      <div key={idx} className={`mobileMenu ${child.open ? "open" : ""}`}>
        <li className="menuItem">
          {/* Accessible parent menu item */}
          <div className="menu-item-container">
          {child.url ? (
            <a href={child.url} className="menu-link">
              {child.label}
            </a>
          ) : (
            <span className="menu-link">{child.label}</span>
          )}
            {hasChildren && (
              <button
                className="submenu-toggle chevron-icon"
                aria-expanded={child.open}
                aria-controls={`submenu-${idx}`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event bubbling
                  e.preventDefault(); // Stop default action on button
                  this._toggleMenuItem(idx); // Toggle submenu
                }}
              >
                <i className="far fa-chevron-right" aria-hidden="true" />
                <span className="sr-only">Toggle submenu for {child.label}</span>
              </button>
            )}
          </div>
          {/* Submenu */}
          {hasChildren && (
            <ul
              id={`submenu-${idx}`}
              className="subMenu"
              role="menu"
              aria-hidden={!child.open}
            >
              {child.children.map((subChild, subIdx) => (
                <li key={subIdx} className="subMenuItems" role="none">
                  <a href={subChild.url} role="menuitem">
                    {subChild.label}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      </div>
    );
  };

  _displayMenu = () => {
    const { menu } = this.state;
    if (!menu || !menu.children || menu.children.length === 0) {
      return null;
    }

    return (
      <div className="menu-list-wrapper">
        <ul className="mmobileMenu">
          {this.props.searchInNav && <InlineSearch classes="menu-search" isSearchOn={true} open={true} static={true} />}

          {this.props.isSignInOn && (
            <li className="font-secondary text-primary subMenuItems">
              <a href="/sign-in">Sign In</a>
            </li>
          )}
          {this.props.isSignInOn && (
            <li className="font-secondary text-primary subMenuItems">
              <a href="/register">Register</a>
            </li>
          )}

          {menu.children.map((child, idx) => {
            return this._displayMenuItems(child, idx);
          })}

          {this.props.showSubscribeBtn && (
            <li className="font-secondary text-primary subMenuItems mobile-subscribe">
              <a href="/subscribe" target="_blank">Subscribe</a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  componentDidMount() {
    let menus = [...this.props.menus];
    let menu = {};

    for (let i = 0; i < menus.length; i++) {
      if (menus[i].label === "Main Navigation") {
        menu = menus[i];
      }
    }

    if (menu.children) {
      menu.children = menu.children.map((child) => ({
        ...child,
        open: false // Initially set to closed
      }));
    }

    this.setState({ menu });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.includeSubscribe && (
          <a className="no-hover-underline" href="/subscribe">
            <button className="action-btn subscribe-btn">Subscribe</button>
          </a>
        )}
        <div className={this.state.open ? "menu open" : "menu"}>
          <button className="btn menu-button open-btn" title="Open Menu" onClick={this._toggleMenu}>
            <i className="far fa-bars" aria-hidden="true" />
          </button>
          <div className="menu-list">
            <a id="menu-brand" href="/"></a>
            <button className="menu-button close-btn" title="Close Menu" onClick={this._toggleMenu}>
              <i className="far fa-times" aria-hidden="true" />
            </button>
            {this._displayMenu()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HamburgerMenu;
